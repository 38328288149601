@import '../../styles/library';

.baseBody {
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: #f1f5f8;
  justify-content: flex-start;
  flex: 1 0 auto;


  .fullPage {
    width: 100%;
    display: flex;
    flex-direction: row;
    min-height: 100vh;
    justify-content: center;

    .contentContainer {
      padding: 1rem;
      background-color: white;;
      max-width: $breakpoint-small;
      @include max-breakpoint-medium {
        max-width: $max-page-width;
      }

      display: flex;
      flex-grow: 1;
      width: 100%;
      min-height: 100vh;
    }
  }
}