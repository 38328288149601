@import '../../styles/library.scss';

.emailDetailsContainer {
  display: flex;
  flex-direction: column;
  width: 100%;
  border-top: solid 1px $grey-color;
}

.mailInfo {
  margin-top: 10px;
  display: flex;
  flex-direction: row;
  width: 100%;
  background-color: $grey-light;

  .info {
    display: flex;
    flex-direction: column;
    width: inherit;
    padding: 16px;
    line-height: 1.5;
    > * {
      margin-bottom: 5px;
    }
  }
  .buttons {
    padding: 5px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }
}

.view {
  margin-top: 10px;
  display: flex;
  width: 100%;
}
