@import '../../styles/library';

.roomFormContainer {
  padding: 15px;

  .content {
    padding: 20px;
    border: solid 1px $black;
    border-radius: 5px;
    box-shadow: $box-shadow;

    .useSteps {
      margin-top: 15px;
    }
  }
}