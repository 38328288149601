@import '../../styles/library';

.headerContainer {
  display: flex;
  flex-direction: row;
  width: 100%;
  height: 40px;
  background-color: $primary-light-color;
  justify-content: center;

  .headerContent {
    display: flex;
    flex-direction: row;
    flex-grow: 1;
    width: 100%;
    padding-left: 10px;
    padding-right: 10px;
    @include max-breakpoint-medium {
      max-width: $max-page-width;
    }
    .title {
      font-weight: 500;
      font-size: $ft-largest;
    }
    .languageSelect {
      margin-right: 0;
      margin-left: auto;
      max-width: 50px;
    }
  }
}