@import './globals';

@mixin breakpoint-small {
  @media (min-width: #{$breakpoint-small}) {
    @content;
  }
}

@mixin max-breakpoint-small {
  @media (min-width: #{$breakpoint-small}) {
    @content;
  }
}

@mixin breakpoint-medium {
  @media (min-width: #{$breakpoint-medium}) {
    @content;
  }
}

@mixin max-breakpoint-medium {
  @media (min-width: #{$breakpoint-medium}) {
    @content;
  }
}

@mixin breakpoint-large {
  @media (min-width: #{$breakpoint-large}) {
    @content;
  }
}
