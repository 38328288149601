@import '../../../styles/library.scss';

.mailInfoContainer {
    display: flex;
    width: 100%;
    flex-direction: row;
    
    .infoTitle {
      font-weight: $ftw-large;
      font-size: $ft-normal;
    }

    .data {
      margin-left: 5px;
    }
  }