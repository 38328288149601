.homeContainer {
  width: 100%;
  height: 100vh;

  .entryRow {
    display: flex;
    flex-direction: row;
    width: 100%;
    justify-content: space-around;

    > * {
      min-width: 40%;
    }

    .form {
      display: flex;
      height: 100%;
      align-items: flex-end;
    }

    .pageTitle {
      display: flex;
      flex-direction: column;
      align-items: center;

      > * {
        margin-top: 10px;
      }

      .appTitle {
        font-size: 3rem;
        font-weight: 700;
        margin-top: 30px;
        margin-bottom: -10px;
      }

      .subTitle{
        font-size: 1.2rem;
        font-weight: 500;
        margin-bottom: 20px;
      }
    }
  }

  .counter {
    display: flex;
    width: 100%;
    flex-direction: column;
    align-items: center;

    .count {
      text-align: center;
      margin: auto;
      font-size: 2.5rem;
    }

    .description {
      font-size: 2rem;
      font-weight: 500;
    }
  }

  .infoRow {
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    > * {
      width: 25%;
    }
  }
}