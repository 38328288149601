$primary-color: #003459;
$primary-light-color: lighten($primary-color, 20%);
$primary-lightest-color: lighten($primary-color, 40%);
$primary-dark-color: darken($primary-color, 20%);
$primary-darkest-color: darken($primary-color, 30%);

$primary: #003459;
$secondary: #007EA7;
$tertiary: #00A8E8;
$septenary: #00171F;
$octonary: #F3AA60;

$white: #fff;
$black: #112a2c;
$danger: #e27668;
$error: #d23b27;
$grey-light: #dadce0;
$separator: #eee;

$grey-dark-color: #00000099;
$grey-color: #9faaa9;
$positive-color: #66BF3C;
$warning: #E69D00;
$info: #32b0ae;


$primary-background-color: #fff;
$secondary-background-color: $primary;

$box-shadow: 0 0 2rem rgba(0, 0, 0, .2);
$box-shadow-closer: 0 0 1rem rgba(0, 0, 0, .2);

$text: #121212;
$text-shadow: 0 0 .3rem rgba(0, 0, 0, .6);

$border-radius: .5rem;

$ftw-large: 500;
$ftw-largest: 700;

$ft-smallest: 0.5rem;
$ft-small: 0.85rem;
$ft-normal: 1rem;
$ft-large: 1.2rem;
$ft-largest: 1.6rem;
$ft-xlarge: 2.6rem;

$ft-dark: $black;
$ft-light: #9faaa9;
$ft-bright: $white;

$z-0: 0;
$z-10: 10;
$z-20: 20;
$z-30: 30;

// Small > Smaller than 640px;
$breakpoint-small: 640px;
// Medium > 641px to 1007px;
$breakpoint-medium: 1007px;
// Large > 7008 and larger
$breakpoint-large: 1008px;

$max-page-width: 1200px;